import React from "react"
import { graphql, StaticQuery } from "gatsby"

const Image = ({ image, caption, url, className, style, widthAttr, width }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 500) {
                originalName
              }
            }
          }
        }
      }
    `}

    render={data => {
      return (
        <>
          <img
            src={image? image.url : url}
            className={"img-fluid" + (className ? " " + className : "")}
            alt={image && image.alt? image.alt : ''}
            style={style}
            width={width ? width : (image && widthAttr? image.dimensions.width : null)}
          />

          {caption ? <div class="image-caption">{caption}</div> : <></>}
        </>
      )
    }}
  />
)

export default Image
